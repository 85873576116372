import { useApiClient } from '~/composables/api/api'
import { date, z, ZodError } from 'zod'
import { FetchError } from 'ofetch'
import _ from 'lodash'

const schema = z
  .object({
    productId: z.number().min(1),
    myProductId: z.number().min(1).optional().nullable(),
    locationId: z.string(),
    plannedSampleNumber: z.string().optional().nullable(),
    sampleType: z.nativeEnum(CreatePlannedSampleRequestSampleType),
    year: z.number().refine((val) => val >= 2023 && val <= 2100, {
      message: 'Year must be a 4 digit number',
    }),
    quarter: z.number().optional().nullable(),
    month: z.number().optional().nullable(),
    date: z.date().optional().nullable(),
    combinedAnalysisAssignments: z.array(
      z.object({
        hazardId: z.number().min(1),
        detectionBorderValue: z.number().nullable(),
      }),
    ),
  })
  .transform((data) => ({
    ...data,
    date: data.date ? new Date(data.date) : undefined,
  }))

export const useCreatePlannedSample = (locationId: string) => {
  const formStore = useFormStore()

  const data = reactive({
    productId: null,
    myProductId: null,
    locationId: locationId,
    plannedSampleNumber: null,
    sampleType: CreatePlannedSampleRequestSampleType.None,
    year: new Date().getFullYear(),
    quarter: null,
    month: null,
    date: null,
    combinedAnalysisAssignments: [] as HazardRelationResponse[],
  })

  const isDirty = ref(false)

  const validationErrors = reactive<{
    stepOne: undefined | Record<string, string[]>
  }>({
    stepOne: undefined,
  })

  watch(
    data,
    () => {
      isDirty.value = true
    },
    { deep: true },
  )

  watch(isDirty, () => {
    formStore.setDirty(isDirty.value)
  })

  const {
    mutateAsync,
    error: mutateError,
    isPending,
  } = useMutation({
    mutationFn: async (data: z.input<typeof schema>) => {
      const dataToValidate = {
        ...data,
        combinedAnalysisAssignments: data.combinedAnalysisAssignments.map((asignment) => {
          return { hazardId: asignment.id, detectionBorderValue: null }
        }),
      }
      schema.parse(dataToValidate)

      return await useApiClient<Promise<PlannedSampleResponse>>('/portal/planned-samples', {
        method: 'POST',
        body: dataToValidate,
      })
    },
    async onSuccess(data) {
      return navigateTo(
        `/portal/risk-management/monitoring-database/my-monitoring/${locationId}/planned-sample/${data.id}`,
      )
    },
  })

  const validateStepOne = () => {
    try {
      const dataToValidate = {
        ...data,
        combinedAnalysisAssignments: data.combinedAnalysisAssignments.map((asignment) => {
          return { hazardId: asignment.id, detectionBorderValue: null }
        }),
      }
      schema.parse(dataToValidate)
      validationErrors.stepOne = undefined
      return true
    } catch (e) {
      if (e instanceof ZodError) {
        validationErrors.stepOne = e.format()
        console.error(e, data)
      }
      return false
    }
  }
  const errors = computed(() => {
    if (validationErrors?.stepOne) return validationErrors.stepOne
    if (mutateError.value instanceof FetchError) return mutateError.value.data
    return mutateError.value
  })

  const submit = async () => await mutateAsync(data)

  return {
    data,
    submit,
    isPending,
    isDirty,
    validateStepOne,
    error: errors,
  }
}
