import { AuthRole } from '~/utils/auth'

export const HubRoutes = [
  {
    name: 'dashboard',
    path: '/hub/dashboard',
    icon: 'ic:outline-dashboard',
    auth: {
      roles: [AuthRole.QRAY_GMP],
    },
  },
  {
    name: 'management',
    path: '/hub/management',
    icon: 'ic:outline-build',
    auth: {
      roles: [AuthRole.QRAY_GMP],
    },
    children: [
      { name: 'products', path: '/hub/management/products' },
      {
        name: 'productGroups',
        path: '/hub/management/product-groups',
      },
      { name: 'hazards', path: '/hub/management/hazards' },
      { name: 'controlMeasures', path: '/hub/management/control-measures' },
      {
        name: 'controlMeasureGroups',
        path: '/hub/management/control-measure-groups',
      },
      { name: 'processingAids', path: '/hub/management/processing-aids' },
      {
        name: 'processingAidGroups',
        path: '/hub/management/processing-aid-groups',
      },
      { name: 'processSteps', path: '/hub/management/process-steps' },
      {
        name: 'processStepGroups',
        path: '/hub/management/process-step-groups',
      },
      {
        name: 'monitoringGroups',
        path: '/hub/management/monitoring-groups',
      },
      { name: 'methods', path: '/hub/management/methods' },
      { name: 'units', path: '/hub/management/units' },
    ],
  },
  {
    name: 'riskAssessments',
    path: '/hub/risk-assessments',
    icon: 'ic:outline-insert-drive-file',
    auth: {
      roles: [AuthRole.QRAY_GMP],
    },
  },
  {
    name: 'monitoring',
    path: '/hub/monitoring',
    icon: 'ic:outline-traffic',
    auth: {
      roles: [AuthRole.QRAY_GMP],
    },
  },
  {
    name: 'incomingEmails',
    path: '/hub/incoming-emails',
    icon: 'ic:baseline-mail-outline',
    auth: {
      roles: [AuthRole.QRAY_GMP],
    },
  },
  {
    name: 'sitemap',
    path: '/hub/sitemap',
    icon: 'ic:baseline-list-alt',
    auth: {
      roles: [AuthRole.QRAY_GMP],
    },
  },
]

export const PortalRoutes = (t: (str: string) => string) => [
  {
    name: 'dashboard',
    path: '/portal/dashboard',
    icon: 'ic:outline-dashboard',
  },
  {
    id: 'menu-feed-scheme-documents',
    name: 'feedSchemeDocuments',
    path: '/portal/feed-scheme-documents',
    icon: 'ic:grain',
  },
  {
    id: 'menu-documents',
    name: 'documents',
    path: '/portal/documents',
    icon: 'ic:baseline-cloud-off',
    auth: {
      roles: [AuthRole.UNRELEASED],
    },
  },
  {
    id: 'menu-risk-management',
    name: 'riskManagementTools',
    path: '/portal/risk-management',
    icon: 'ic:outline-traffic',
    auth: {
      roles: [AuthRole.QRAY_GMP, AuthRole.QRAY_FSP, AuthRole.QRAY_MON, AuthRole.PORTAL_COMPANY],
    },
    children: [
      {
        name: 'riskAssessments',
        path: '/portal/risk-management/risk-assessments',
      },
      {
        name: 'acceptedFeedMaterials',
        path: '/portal/risk-management/accepted-feed-materials',
      },
      {
        name: 'processingAids',
        path: '/portal/risk-management/processing-aids',
      },
      {
        name: 'undesirableSubstances',
        path: '/portal/risk-management/undesirable-substances',
      },
      {
        name: 'monitoringDatabase',
        path: '/portal/risk-management/monitoring-database',
        auth: {
          roles: [
            AuthRole.QRAY_GMP,
            AuthRole.QRAY_MON,
            AuthRole.QRAY_MANAGER,
            AuthRole.PORTAL_COMPANY,
          ],
        },
        children: [
          {
            name: 'myMonitoring',
            path: {
              path: '/portal/risk-management/monitoring-database/my-monitoring',
              query: {
                levels: 'own',
              },
            },
          },
          {
            name: 'myProducts',
            path: '/portal/risk-management/monitoring-database/my-products',
          },
          {
            name: 'analysisSets',
            path: '/portal/risk-management/monitoring-database/analysis-sets',
            disabled: false,
          },
          {
            name: 'myGroups',
            path: '/portal/risk-management/monitoring-database/my-groups',
            disabled: false,
          },
          {
            name: 'groupManagement',
            path: '/portal/risk-management/monitoring-database/management-group-members',
            disabled: false,
            auth: {
              roles: [AuthRole.QRAY_GMP, AuthRole.QRAY_MANAGER],
            },
          },
          {
            name: 'reports',
            path: '/portal/risk-management/monitoring-database/reports',
            disabled: false,
            auth: {
              roles: [AuthRole.UNRELEASED],
            },
          },
          {
            name: 'codeLists',
            path: '/portal/risk-management/monitoring-database/code-lists',
          },
        ],
      },
    ],
  },
  {
    name: 'gmpCompanyDatabase',
    icon: 'ic:baseline-domain',
    path: '/portal/gmp-company-database',
    children: [
      {
        name: 'certifiedCompanies',
        path: t('externalLinks.companyDatabase.certifiedCompanies'),
        target: '_blank',
      },
      {
        name: 'certificationBodies',
        path: t('externalLinks.companyDatabase.certificationBodies'),
        target: '_blank',
      },
      {
        name: 'laboratories',
        path: t('externalLinks.companyDatabase.laboratories'),
        target: '_blank',
      },
      {
        name: 'partners',
        path: t('externalLinks.companyDatabase.partners'),
        target: '_blank',
      },
      {
        name: 'otherCertificationSchemes',
        path: t('externalLinks.companyDatabase.otherCertificationSchemes'),
        target: '_blank',
      },
    ],
  },
  {
    name: 'earlyWarningSystem',
    path: t('externalLinks.earlyWarningSystem'),
    icon: 'ic:baseline-warning-amber',
    target: '_blank',
    auth: {
      roles: [AuthRole.PORTAL_COMPANY],
    },
  },
  {
    name: 'cbLogin',
    path: t('externalLinks.cbLogin'),
    icon: 'ic:baseline-card-travel',
    target: '_blank',
    auth: {
      roles: [
        AuthRole.PORTAL_EWS,
        AuthRole.PORTAL_ADM,
        AuthRole.PORTAL_COORDINATOR,
        AuthRole.PORTAL_SCAC,
        AuthRole.PORTAL_TCFSP,
        AuthRole.PORTAL_STAC,
        AuthRole.PORTAL_SRF,
        AuthRole.PORTAL_SP,
        AuthRole.PORTAL_IECAF,
      ],
    },
  },
  {
    name: 'exemptions',
    path: t('externalLinks.exemptions'),
    icon: 'ic:outline-folder-special',
    target: '_blank',
    auth: {
      roles: [AuthRole.PORTAL_EXEMPTION, AuthRole.PORTAL_COMPANY],
    },
  },
]
