import { z } from 'zod'
import type { CreateAnalysisReportRequest } from '~/clients'
import { useQueryClient } from '@tanstack/vue-query'
import { navigateTo } from '#app/composables/router'

const schema = z.object({
  combinedSampleId: z.string(),
  guid: z.string().nullish(),
  laboratoryLocationId: z.number().optional(),
  date: z.string(),
  analysisResults: z.array(
    z.object({
      guid: z.string().nullish(),
      symbol: z.string().nullish(),
      hazardId: z.number(),
      methodId: z.number().nullish(),
      analysisReportGuid: z.string().nullish(),
      value: z
        .number()
        .or(z.string({ required_error: '', invalid_type_error: '' }).min(1))
        .pipe(z.coerce.number()),
    }),
    // .or(
    //   z.object({
    //     symbol: z.string().nullish(),
    //     hazardId: z.number(),
    //     methodId: z.number().nullish(),
    //     analysisReportGuid: z.string().nullish(),
    //     value: z.coerce.number(),
    //   }),
    // ),
  ),
  code: z.string().min(1),
  explanation: z.string().nullish(),
  moisturePercentage: z.number().min(0.01).max(99.99),
})

export const useManageAnalysisReports = (
  response: SampleResponse,
  type: 'create' | 'update',
  analysisReportId?: string,
) => {
  const formStore = useFormStore()
  const queryClient = useQueryClient()
  const { params } = useRoute()

  const selectedAnalysisReport = computed(() => {
    if (type === 'create') return {}
    return (
      response.analysisReports?.find((a) => a.guid === analysisReportId) ??
      response.analysisReports[0] //fallback to first for legacy reasons
    )
  })

  const data = ref<z.input<typeof schema>>({
    laboratoryLocationId: selectedAnalysisReport.value?.laboratoryLocationId,
    date: selectedAnalysisReport.value?.date,
    code: selectedAnalysisReport.value?.code,
    analysisResults: selectedAnalysisReport.value?.analysisResults ?? [
      {
        guid: null,
        hazardId: null,
        methodId: null,
        value: null,
        symbol: null,
        ...(type === 'update' ? { analysisReportGuid: response.analysisReports[0].guid } : {}),
      },
    ],
    explanation: selectedAnalysisReport.value?.explanation,
    moisturePercentage: selectedAnalysisReport.value?.moisturePercentage ?? 12,
    combinedSampleId: response.id,
    ...(type === 'update' ? { guid: response.analysisReports[0].guid } : {}),
  })

  return useManageApi<AnalysisSetResponse, CreateAnalysisReportRequest>(
    type,
    selectedAnalysisReport.value?.guid, // Todo? check if we need to have the first?
    `portal/samples/${response.id}/analysis-reports`,
    data,
    'Analysis report',
    {
      onSuccess: async () => {
        if (type === 'create') {
          await navigateTo(
            `/portal/risk-management/monitoring-database/my-monitoring/${params.locationId}/sample/${params.id}/analysis-report`,
          )
        }

        formStore.reset()
        await queryClient.invalidateQueries(['samples', params.id])
      },
    },
    schema,
  )
}
