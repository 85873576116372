import { ref, computed, type Ref, type UnwrapRef } from 'vue'
import { ZodType, type ZodTypeDef, z, type ZodFormattedError } from 'zod'
import * as Sentry from '@sentry/vue'

export const useForm = <T>(
  defaultValue: Ref,
  onSubmit: (value: UnwrapRef<T>) => void,
  validation: ZodType<T, ZodTypeDef, T>,
) => {
  const internalValue = ref<T>(useCloneDeep(defaultValue.value))
  const errors = ref<ZodFormattedError<z.infer<typeof validation>>>()

  const isDirty = computed(() => {
    return JSON.stringify(internalValue.value) !== JSON.stringify(defaultValue.value)
  })

  const reset = () => {
    internalValue.value = useCloneDeep(defaultValue.value)
  }

  const validate = () => {
    const validationResult = validation.safeParse(internalValue.value)
    errors.value = validationResult.success ? undefined : validationResult.error.format()

    if (!validationResult.success) {
      Sentry.captureException(validationResult.error, {
        data: validationResult.data,
      })
    }

    if (!validationResult.success && import.meta.env.DEV) {
      console.error(validationResult.error)
    }

    return validationResult.success
  }

  const submit = () => {
    if (validate()) {
      onSubmit(internalValue.value)
    }
  }

  return { form: internalValue, isDirty, reset, submit, errors }
}
